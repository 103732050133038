<template>
  <v-card
    elevation="2"
    color="background"
    class="d-flex flex-column"
    style="height: 100%"
  >
    <v-card-title>
      الحالات
      <v-spacer></v-spacer>

      <DefaultDialog
        buttonText="اضافة"
        title="اضافة حالة"
        :openToEdit="openToEdit"
        editTitle="تعديل حالة"
        :openToView="openToView"
        viewTitle="عرض البيانات"
        @onCloseDialog="onCloseDialog"
        :showAddButton="isCanCreate"
      >
        <ContentStatusDetail
          slot-scope="{
            submitWithClose,
            submitWithNew,
            submitWithoutClose,
            closeDialog,
            isEdit,
            isView,
            isNew,
          }"
          :submitWithClose="submitWithClose"
          :submitWithNew="submitWithNew"
          :submitWithoutClose="submitWithoutClose"
          :setItemID="setItemID"
          :closeDialog="closeDialog"
          :afterSubmit="getContentStatus"
          :itemId="itemId"
          :isEdit="isEdit"
          :isView="isView"
          :isNew="isNew"
        />
      </DefaultDialog>
    </v-card-title>
    <v-card-text
      class="d-flex flex-column justify-space-between"
      style="flex: 1; min-height: 0"
    >
      <!-- Filters -->
      <v-row style="flex: 0">
        <!-- <v-col cols="12" md="12">
          <v-text-field
            v-model="options.title"
            flat
            hide-details="auto"
            label="بحث"
            outlined
            prepend-inner-icon="mdi-magnify"
            solo
          ></v-text-field>
        </v-col> -->
        <v-col cols="12">
          <languages-filter @change="changeLanguage" />
        </v-col>
      </v-row>

      <!-- Grid -->
      <v-row class="mt-0" style="min-height: 0">
        <v-col cols="12" style="flex: auto; min-height: 0; height: 100%">
          <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="contentStatus"
            :items-per-page="options.pageSize"
            :loading="$store.state.loading.show"
            :ripple="false"
            fixed-header
            hide-default-footer
            height="100%"
            style="height: 100%; overflow: auto"
            loading-text="جار التحميل..."
            no-data-text="لا يوجد"
          >
            <template v-slot:[`item.controls`]="props">
              <v-btn
                v-if="isCanRead"
                small
                color="#78b200"
                @click="viewRow(props.item)"
                class="mr-1"
              >
                <v-icon color="#fff">mdi-eye</v-icon>
              </v-btn>

              <v-btn
                v-if="isCanUpdate"
                small
                color="#ffa000"
                @click="editRow(props.item)"
                class="mr-1"
              >
                <v-icon color="#fff">mdi-pencil-outline</v-icon>
              </v-btn>

              <v-btn
                v-if="isCanDelete"
                small
                color="pink"
                @click="deleteRow(props.item)"
                class="mr-1"
              >
                <v-icon color="#fff">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- pagination -->
      <v-row class="mt-0" style="flex: 0">
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <v-pagination v-model="options.pageIndex" :length="numberOfPages" />
          <v-select
            v-model="options.pageSize"
            :items="[5, 10, 15, 20]"
            label="عدد الصفوف"
            solo
            dense
            hide-details
            item-color="white"
            class="flex-grow-0"
            style="width: 100px"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import qs from 'qs';
import LanguagesFilter from '@/components/LanguagesFilter.vue';
import { selectedLanguage } from '@/config/config';
import DefaultDialog from '@/components/DefaultDialog.vue';
import ContentStatusDetail from './crud/ContentStatusDetail.vue';

import { mapActions } from 'vuex';
import { showSnackbarFailMessage } from '@/utls/snackbar';
import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';

export default {
  components: {
    LanguagesFilter,
    ContentStatusDetail,
    DefaultDialog,
  },
  mixins: [PermissionMixin(appPermissions.contentStatus)],
  data() {
    return {
      sel: null,
      contentStatus: [],
      total: 0,
      headers: [
        { text: 'slug', value: 'slug' },
        { text: 'الأسم', value: 'language.name' },
        { text: '', value: 'controls', sortable: false },
      ],
      options: {
        pageIndex: 1,
        pageSize: 15,
        language: selectedLanguage,
        title: '',
      },
      // for view edit and detail
      // ------------------------
      openToEdit: false,
      openToView: false,
      itemId: null,
      // ------------------------
    };
  },
  computed: {
    skipPerPage() {
      return this.options.pageSize * (this.options.pageIndex - 1);
    },
    numberOfPages() {
      const pages = Math.ceil(this.total / this.options.pageSize) || 1;
      return pages;
    },
  },
  created() {
    this.getContentStatus();
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    deleteRow(item) {
      this.openConfirm({
        message: 'هل انت متأكد من حذف الحالة ؟',
        confirm: () => {
          return this.$http
            .delete('app/content-status/' + item.id, {})
            .then(() => {
              this.getContentStatus();
              return true;
            })
            .catch((error) => {
              showSnackbarFailMessage('حدث خطأ اثناء عملية الحذف');
              return false;
            });
        },
      });
    },
    onCloseDialog() {
      this.openToEdit = false;
      this.openToView = false;
    },
    editRow(item) {
      this.openToEdit = true;
      this.itemId = item.id;
    },
    viewRow(item) {
      this.openToView = true;
      this.itemId = item.id;
    },
    setItemID(id) {
      if (id) return this.editRow({ id });
      this.itemId = null;
      this.onCloseDialog();
    },
    changeLanguage(language) {
      this.options.language = language;
    },
    getContentStatus() {
      this.$store.commit('loading/SET_SHOW', true);

      const requestConfig = {
        params: {
          skipCount: this.skipPerPage,
          maxResultCount: this.options.pageSize,
          language: this.options.language,
          title: this.options.title,
        },
        paramsSerializer: function (params) {
          const queryString = qs.stringify(params, { arrayFormat: 'brackets' });
          return queryString;
        },
      };

      this.$http
        .get('app/content-status', requestConfig)
        .then((response) => {
          const { items, totalCount } = response.data;

          const resultData = items.reduce((p, c) => {
            const language = c.contentStatusLanguages[0];
            const data = [...p, { ...c, language }];
            return data;
          }, []);

          this.contentStatus = resultData;
          this.total = totalCount;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
        })
        .finally(() => this.$store.commit('loading/SET_SHOW', false));
    },
    searchUser() {},
  },
  watch: {
    options: {
      handler() {
        this.getContentStatus();
      },
      deep: true,
    },
  },
};
</script>
